@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply lg:text-7xl text-left text-6xls lg:my-8 my-6;
    font-stretch: expanded;
  }
  h2 {
    @apply lg:text-6xl text-4.5xls;
    font-stretch: expanded;
  }
  h3 {
    @apply lg:text-4xl text-3xls;
    font-stretch: expanded;
  }
  h5 {
    @apply text-xls lg:text-2xl;
    font-stretch: expanded;
  }
  p {
    @apply lg:text-1.5xl lg:text-justify lg:font-black [font-stretch:expanded] text-lgs text-left;
  }
  ol {
    @apply list-inside list-decimal;
    font-stretch: normal;
  }
  li {
    @apply max-md:text-justify my-1 normal-case;
    font-stretch: normal;
  }
}

@layer components {
  /* Glowing border */
  .border-glow {
    box-shadow: #e32020 0px 2px 20px -3px;
  }

  .glow {
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 10px red, -2px 2px 10px red, 2px -2px 10px red,
      -2px -2px 10px red, 2px 2px 20px rgba(255, 0, 0, 0.5),
      -2px 2px 20px rgba(255, 0, 0, 0.5), 2px -2px 20px rgba(255, 0, 0, 0.5),
      -2px -2px 20px rgba(255, 0, 0, 0.5), 0px 0px 5px white;
  }

  /* glowing button */
  .glow-button {
    --glow-color: rgb(255, 255, 255);
    --glow-spread-color: rgba(255, 0, 0, 0.781);
    --enhanced-glow-color: rgb(255, 206, 206);
    --btn-color: rgb(104, 0, 0);
    border: 2px solid var(--glow-color);
    padding: 1em 3em;
    color: var(--glow-color);
    font-size: 15px;
    font-weight: bold;
    background-color: var(--btn-color);
    border-radius: 1em;
    outline: none;
    box-shadow: 0 0 1em 0.25em var(--glow-color),
      0 0 4em 1em var(--glow-spread-color),
      inset 0 0 0.75em 0.25em var(--glow-color);
    text-shadow: 0 0 0.5em var(--glow-color);
    position: relative;
    transition: all 0.3s;
  }

  .glow-button::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--glow-spread-color);
    filter: blur(2em);
    opacity: 0.7;
    transform: perspective(1.5em) rotateX(35deg) scale(1, 0.6);
  }

  .glow-button:hover {
    color: var(--btn-color);
    background-color: var(--glow-color);
    box-shadow: 0 0 1em 0.25em var(--glow-color),
      0 0 4em 2em var(--glow-spread-color),
      inset 0 0 0.75em 0.25em var(--glow-color);
  }

  .glow-button:active {
    box-shadow: 0 0 0.6em 0.25em var(--glow-color),
      0 0 2.5em 2em var(--glow-spread-color),
      inset 0 0 0.5em 0.25em var(--glow-color);
  }

  /* glowing button end*/
  .radial-bg-red-black{
    background: radial-gradient(93.24% 93.24% at 30.76% 13.94%, #691809 0%, #240803 36.04%, #000000 100%);
  }

  .gradient-bg {
    background-color: transparent;
    background-image: linear-gradient(181deg, #000 0%, #F3030388 100%);
    transition-property: background-image;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .gradient-bg:hover {
    background-image: linear-gradient(180deg, #000000 0%, #F30303 100%);
  }
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-main text-main;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-weight: 400;
}
